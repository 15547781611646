import React from 'react';
import { useRoutes } from "react-router-dom";

/* Components Pages */
import BranchComponent from './pages/components/branch/branchComponent';

/* Admin Pages */
import PrivateRoute from './pages/admin/PrivateRoute';
import LoginMasterLayout from './pages/admin/layout/LoginMasterLayout';
import Login from './pages/admin/pages/login';

import DashboardLayout from './pages/admin/layout/DashboardLayout';
import Dashboard from './pages/admin/pages/dashboard';
import PossibleResponseGroup from './pages/admin/pages/PossibleResponseGroup';


export default function App() {
    const isAuth = true;

    const routes = useRoutes([
        //Workflow Components Routes
        {
            path: "/branchcomponent",
            element: <BranchComponent />
        },

        //Admin Routes
        {
            path: "/",
            element: <LoginMasterLayout><Login /></LoginMasterLayout>
        },
        {
            path: "/dashboard",
            element: <PrivateRoute isAuth={isAuth}>
                <DashboardLayout><Dashboard /></DashboardLayout>
            </PrivateRoute>
        },
        {
            path: "/PossibleResponseGroup",
            element: <PrivateRoute isAuth={isAuth}>
                <DashboardLayout><PossibleResponseGroup /></DashboardLayout>
            </PrivateRoute>
        },
        {
            path: "*",
            element: <LoginMasterLayout><Login /></LoginMasterLayout>
        }
    ])
    return routes;
}
