
import axiosInterceptor from "../middleware/axiosInterceptor";

export const fetchBranchInitialData = async () => {
    const url = "/api/workflow/Component/branch/initial";
    try {
        const response = await axiosInterceptor.get(url);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
};