
import React from 'react';
import { Layout, Menu, Avatar, Dropdown } from 'antd';
import { UserOutlined, LogoutOutlined, MessageOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Header, Content, Sider } = Layout;

const DashboardLayout = ({ children, userName, onLogout }) => {
  const navigate = new useNavigate();

  const handleClick = (e) => {
    navigate(e.key);
  };

  const menu = (
    <Menu>
      userName
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={onLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        breakpoint="lg"
        collapsible="true"
        collapsedWidth="0"
      >
        <div className="logo" style={{ height: '32px', margin: '16px', background: 'rgba(255, 255, 255, 0.2)' }} />
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
          <Menu.Item key="/PossibleResponseGroup" icon={<MessageOutlined />} onClick={handleClick}>
            Possible Response
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header style={{ background: '#fff', padding: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ marginLeft: '16px' }}>First Outcomes</div>
          <div style={{ marginRight: '16px' }}>
            <Dropdown menu={menu} trigger={['click']}>
              <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                <Avatar icon={<UserOutlined />} /> {userName}
              </span>
            </Dropdown>
          </div>
        </Header>
        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
          <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
