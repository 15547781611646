import axios from 'axios';

// Create an Axios instance
const axiosInterceptor = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URI // Your API base URL
});

// Request interceptor
axiosInterceptor.interceptors.request.use(
  (config) => {
    // const token = localStorage.getItem('token');
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    return config;
  },
  (error) => {
    // Handle request errors    
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInterceptor.interceptors.response.use(
  (response) => {
    // const token = response.data.accessToken;
    // if (token) {
    //   localStorage.setItem('token', token);
    // }
    return response;
  },
  (error) => {
    // Handle response errors
    if (error.response.status === 401) {
      return error.response;
    }
    return Promise.reject(error);
  }
);

export default axiosInterceptor;
